import { EffyEnvironment } from '@effy-tech/angular-common/environment';

const URL_BASE_BO_LOCAL = process.env.URL_BASE_BO_LOCAL || 'http://effy-back:800';
const URL_BASE_BO = process.env.URL_BASE_BO || 'https://bo.rct.effy.quelleenergie.net';

export const environment = {
  production: true,
  env: EffyEnvironment.Rct,
  graphqlApiEndpoint: URL_BASE_BO_LOCAL + '/graphql',
  url_base_bo: URL_BASE_BO + '/',
  url_base_bo_local: URL_BASE_BO_LOCAL + '/',
  jobsUrlApi: 'https://api.smartrecruiters.com/jobs',
  jobsTokenApi: 'DCRA1-93acfb1f4edf4a90ad3d6ca682355799',
  ecGatewayNginx: 'https://ec.gateway.rct.effyconnect.net/',
  apiKey: 'AIzaSyBvI4NMUSkYfbKun0yTum18DWYhxkfKUE4',
  api: {
    address: 'https://address.rct.api.quelleenergie.net/',
  },
};
